/**
 * @flow
 */

import React from 'react';
import Layout from 'components/layout';
import TitleAndMetaTags from 'components/TitleAndMetaTags';
import Container from 'components/Container';
import Header from 'components/Header';
import { sharedStyles } from 'theme';

type Props = {
  location: Location
};

const PageNotFound = ({ location }: Props) => (
  <Layout location={location}>
    <Container>
      <div css={sharedStyles.articleLayout.container}>
        <div css={sharedStyles.articleLayout.content}>
          <Header>Øv! Siden blev ikke fundet</Header>
          <TitleAndMetaTags title="Siden blev ikke fundet - Lejeloven.dk" />
          <div css={sharedStyles.markdown}>
            <p>Vi kunne desværre ikke finde den side du ledte efter</p>
            <p>Tjek venligst at du har indtastet den rigtige adresse</p>
          </div>
        </div>
      </div>
    </Container>
  </Layout>
);

export default PageNotFound;
